
function sendTrackingData(text) {

    const PAGETYPE_FIELD = document.getElementById('pagetype'),
        pageTypeValue = PAGETYPE_FIELD ? PAGETYPE_FIELD.value : 'generic';

    const defaultData = {
        'event': 'on_page_link_click',
        'elementPlacement': 'hero',
        'elementClicked': 'text to speech button',
        'pageType': pageTypeValue,
        'pageUrl': window.location.href,
        'position': 'single',
        'linkText': text,
    };
    window.dataLayer.push(defaultData);

    console.log(defaultData);
}
function activateTextToSpeech()
{
    let textToSpeak = '';
    const textToSpeechElements = document.querySelectorAll('.text-to-speech'),
        btns = document.querySelectorAll('#text-to-speech');

    if ('speechSynthesis' in window && /Android/i.test(navigator.userAgent) !== true) {
        const synth = window.speechSynthesis;

        if (typeof textToSpeechElements !== 'undefined' && textToSpeechElements !== null) {
            for (let i = 0; i < textToSpeechElements.length; i++) {
                textToSpeak += ' '+textToSpeechElements[i].innerText+'.';
            }
        }

        synth.cancel();
        const msg = new SpeechSynthesisUtterance(textToSpeak);
        msg.rate = 0.85;
        msg.volume = 1;
        msg.pitch = 1;

        if (typeof btns !== 'undefined' && btns !== null) {
            for (let i = 0; i < btns.length; i++) {
                btns[i].classList.add('show');
                btns[i].addEventListener('click', function () {
                    const btnText = this.querySelector('span');
                    btnText.textContent = 'Listen Android';
                    if (!this.classList.contains('active') && !this.classList.contains('running')) {
                        this.classList.add('running');
                        btnText.textContent = 'Pause';
                        synth.speak(msg);
                        msg.onerror = (event) => {
                            console.error(
                                `An error has occurred with the speech synthesis: ${event.error}`,
                            );
                        };
                        msg.onend = () => {
                            this.classList.remove('active', 'running');
                            btnText.textContent = 'Listen to Article';
                            synth.cancel();
                            sendTrackingData('end');
                        };
                        sendTrackingData('start');
                    } else if (this.classList.contains('active') && !this.classList.contains('running')) {
                        this.classList.add('running');
                        this.classList.remove('active');
                        btnText.textContent = 'Pause';
                        synth.resume();
                        sendTrackingData('resume');
                    } else {
                        btnText.textContent = 'Resume Play';
                        this.classList.remove('running');
                        this.classList.add('active');
                        synth.pause();
                        sendTrackingData('pause');
                    }
                });
            }
        }
    } else {
        for (let i = 0; i < btns.length; i++) {
            btns[i].style.display = 'none';
        }
        console.log('no support');
    }
}


export default function init()
{
    activateTextToSpeech();
}
