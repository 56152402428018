// Zip code validation and auto submit
function activateZipForm()
{
    function validateFinderZip($value)
    {

        var zipCodesList = window.formZipCodes ?? {};
        var reg = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

        if ($value.length < 5) {
            return false;
        } else if (!reg.test($value)) {
            return false;
        } if (Object.values(zipCodesList).includes($value) === false) {
            return false;
        }

        return true;

    }

    const zipSearch = document.getElementsByClassName('form_button');
    if (typeof zipSearch !== 'undefined' && zipSearch !== null) {
        Array.prototype.forEach.call(zipSearch, function (el) {
            const
                form = el.form,
                zipInput = form.querySelector('.zip_input');

            zipInput.addEventListener('keyup', function () {
                if (this.value.length == 5) {
                    checkForm(el, zipInput, form);
                }
            })

            el.addEventListener('click', function (e) {
                e.preventDefault();
                checkForm(el, zipInput, form);
            });
        })
    }

    function checkForm(el, zipField, form)
    {
        el.classList.add('processing');

        const
            containers = document.querySelectorAll('.section__form'),
            valid = validateFinderZip(zipField.value);

        if (!valid) {
            el.classList.remove('processing');
            containers.forEach((e) => {
                e.classList.add('show_error');
            });
        } else {
            form.submit();
            form.dispatchEvent(new Event('submit'));
            containers.forEach((e) => {
                e.classList.remove('show_error');
            });
        }
    }

    const stickyC2a = document.querySelector('.sticky-v');
    const desktopC2a = document.querySelector('.desktop-v');
    let targetPOS = 0,
        targetRect = '';

    // Sticky call to action
    if (document.querySelector('.senior-finder-form') && desktopC2a != null) {
        targetRect = desktopC2a.getBoundingClientRect();
        targetPOS = targetRect.top;

        if (stickyC2a != null) {
            // Master on timeout to avoid running scroll event too often
            stickyCallMaster();
            // Recalculate on window resize and run the function again
            onResizeDone(stickyCallMaster);
        }
    }

    // On resize function
    let resizeTimer;

    // On resize play function
    function onResizeDone(fun)
    {
        window.addEventListener('resize', () => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                fun();
            }, 250);
        });
    }

    function showStickyC2a()
    {
        setTimeout(() => {
            stickyC2a.classList.add('sticky-active');
        }, 100);
    }

    function hideStickyC2a()
    {
        stickyC2a.classList.remove('sticky-active');
    }

    // Flags
    let happened = null;
    function stickyCallMaster()
    {
        window.addEventListener('scroll', () => {
            if (window.pageYOffset >= targetPOS && happened === null) {
                showStickyC2a();
                happened = true;
            }
            if (window.pageYOffset < targetPOS && happened === true) {
                hideStickyC2a();
                happened = null;
            }
        });
    }
}


export default function init()
{
    activateZipForm();
}
