export default () => {
    if (document.readyState !== 'loading') {
        init();

    } else {
        document.addEventListener('DOMContentLoaded', function () {
            init();
        });
    }

    function init() {
        let style = document.createElement('style');
        const css = ':root { --scrollbar-width: '+(window.innerWidth - document.documentElement.clientWidth) + 'px !important; }';
        
        style.appendChild(document.createTextNode(css));
        style.setAttribute('type', 'text/css');
        
        document.getElementsByTagName('head')[0].appendChild(style);
    }
}