const USER_INTERACTION_EVENTS = [
    'scroll', 'click', 'keydown', 'mousemove', 'touchstart',
];

function runOnUserInteraction(callback) {
    // must save this here or we won't be able to remove events after callback is fired
    const enhancedCallback = () => {
        callback();
        USER_INTERACTION_EVENTS.forEach((uiEvent) => {
            window.removeEventListener(uiEvent, enhancedCallback);
        });

        // Prevent iOS auto zoom on input focus
        if(navigator.userAgent.indexOf('iPhone') > -1 )
        {
            document
            .querySelector('[name=viewport]')
            .setAttribute('content','width=device-width, initial-scale=1, maximum-scale=1');
        }
    }
    USER_INTERACTION_EVENTS.forEach((uiEvent) => {
        window.addEventListener(uiEvent, enhancedCallback);
    });
}

export default runOnUserInteraction;