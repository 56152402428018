export function initializeTableOfContents() {
  const tableOfContentsContainer = document.querySelector('.anchor-section, .js-table-of-contents');
  const tableOfContentsList = document.querySelector('.anchor-section ul, .js-table-of-contents .toc-content');
  const tableOfContentsListHeight = tableOfContentsList?.offsetHeight;
  const fadeDiv = document.createElement('div');
  const showMoreButton = document.createElement('span');
  let containsAnchor = false;

  if (tableOfContentsContainer !== null && tableOfContentsContainer.classList.contains('anchor-section')) {
    containsAnchor = true;
  }

  let isOpen = false;

  // make sure height is larger than 200
  if (tableOfContentsListHeight !== undefined ) {
    tableOfContentsContainer.addEventListener('click', handleClick);
    tableOfContentsList.classList.add('table-of-contents--loaded');
    tableOfContentsList.classList.add('toc-menu-tracking');
    // background fade
    if(tableOfContentsListHeight > 200 && containsAnchor) {
      fadeDiv.classList.add('fade-background')
      tableOfContentsList.appendChild(fadeDiv);
      // show more button
      showMoreButton.classList.add('show-more');
      showMoreButton.innerHTML = 'Show More';
      tableOfContentsContainer.appendChild(showMoreButton);
    }

  }

  function handleClick(){
    if(isOpen){
      tableOfContentsList.classList.remove('active');
      if(tableOfContentsListHeight > 200 && containsAnchor) {
        fadeDiv.style.display = 'block';
        fadeDiv.style.display = 'block';
        showMoreButton.innerHTML = 'Show More'
      }
      isOpen = false;
    } else {
      tableOfContentsList.classList.add('active')
      if(tableOfContentsListHeight > 200 && containsAnchor) {
        fadeDiv.style.display = 'none';
        showMoreButton.innerHTML = 'Show Less';
      }
      isOpen = true;
    }
  }
}

export function mobileTableOfContents() {
  const tocHeader = document.querySelector('.toc-label');
    let isOpen = false;

    if(tocHeader) {
        tocHeader.addEventListener('touchstart', function(){
            if(!isOpen){
                this.nextElementSibling.classList.add('touch-open');
                isOpen = true;
            } else {
                this.nextElementSibling.classList.remove('touch-open');
                isOpen = false;
            }
        })
    }
}
