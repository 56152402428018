import './components/carousel';
import './components/cards';
import './components/similarproducts';
import scrollbarWidth from './components/scroll-bar-width';
import validate from './components/zip-form';
import activateTextToSpeech from './components/text-to-speech';
import {initializeTableOfContents} from './components/tableOfContents';
import microModal from './components/micro-modal';
import runOnUserInteraction from './utilities/run-on-interaction';
import collapsedContent from './components/collapsable-content';
import {initializeLeadForm} from './components/directory-lead-form';

const userInteractionScripts = () => {
    scrollbarWidth();
    validate();
    activateTextToSpeech();
    initializeTableOfContents();
    microModal();
    collapsedContent();
    initializeLeadForm();
}

runOnUserInteraction(userInteractionScripts);
