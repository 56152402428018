import MicroModal from 'micromodal';

function triggerIframeAction(action, container) {
    const video = container.querySelector('.modal-youtube-video .rll-youtube-player iframe');
    if (video && video.contentWindow) {
        video.contentWindow.postMessage(`{"event":"command", "func":"${action}", "args":""}`, '*');
    }
}

export default function init(callbackOnClose = null)
{
    console.log('MicroModal inside component.');
    // Added code below to repair a known issue with MicroModal See; https://github.com/ghosh/Micromodal/issues/324
    MicroModal.init({
        disableFocus: true,
        onShow: function (modal, element) {
            // Disable scrolling on the body when modal is open
            document.body.classList.add('no-scroll');
            let button = modal.querySelector('.modal-youtube-video .rll-youtube-player');
            const video = modal.querySelector('.modal-youtube-video .rll-youtube-player iframe');
            if (video && video.contentWindow) {
                triggerIframeAction('playVideo', modal);
            } else if (typeof button !== 'undefined' && button !== null && !element.classList.contains('feedback-button')) {
                button = button.querySelector('button.play');
                button.addEventListener('click', e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setTimeout(function () {
                        triggerIframeAction('playVideo', modal);
                    }, 500);
                });
                button.click();
            }
        },
        onClose: function (modal, element, event) {
            event.preventDefault();
            event.stopPropagation();
            triggerIframeAction('pauseVideo', modal);
            // Enable scrolling on the body when modal is closed
            document.body.classList.remove('no-scroll');
            if (callbackOnClose !== null) {
                callbackOnClose();
            }

            const defaultData = {
                'event': 'on_page_link_click',
                'elementPlacement': 'modal',
                'elementClicked': 'close-button',
                'pageType': window.sl_pagetype,
                'pageUrl': window.location.href,
                'position': 'single',
                'direction': 'close',
            };
            window.dataLayer.push(defaultData);
            console.log(defaultData);
        },
    });
}
