
// Load TrustedForms script
(function () {
    var field = 'xxTrustedFormCertUrl';
    var provideReferrer = false;
    var invertFieldSensitivity = false;
    var tf = document.createElement('script');
    tf.type = 'text/javascript';
    tf.async = true;
    tf.src = 'http' + ('https:' == document.location.protocol ? 's' : '') + '://cfptwebapi.cfdomains.com/trustedform.js?provide_referrer=' + escape(provideReferrer) + '&field=' + escape(field) + '&l=' + new Date().getTime() + Math.random() + '&invert_field_sensitivity=' + invertFieldSensitivity;
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(tf, s);
})();

const submitLytics = (formSubmissionData) => {
    const lyticsVars = {
        domainVisited: `${window.location.protocol}//${window.location.hostname}/`,
        lastPageVisited: window.location.pathname,
        email:formSubmissionData.get('email_address'),
        first_name: formSubmissionData.get('first_name'),
        last_name: formSubmissionData.get('last_name'),
        phone: formSubmissionData.get('phone_home'),
        zip: formSubmissionData.get('zip_code'),
        q_interest: formSubmissionData.get('careTypeCode'),
        q_relationship_name: formSubmissionData.get('relationToResident'),
        q_timeframe: formSubmissionData.get('moveDateRange'),
        q_financial: formSubmissionData.get('financialResources'),
        q_cost: formSubmissionData.get('budget'),
        dataStream: 'seniorliving_org',
    };

    if (window.cfLytics && typeof window.cfLytics === 'function' &&
        window.jstag && typeof window.jstag.getEntity === 'function') {
        window.cfLytics(lyticsVars);
        console.log(lyticsVars);
    } else {
        window.dataLayer.push({
            event: 'cfLyticsIsNotDefined',
        });
        console.log('cfLyticsIsNotDefined');
    }
};

const formatPhoneNumber = (phoneInput) => {
    phoneInput.addEventListener('input', (e) => {
        let number = e.target.value.replace(/[^\d]/g, ''); // Remove all non-digit characters
        if (number.length === 7) {
            number = number.replace(/(\d{3})(\d{4})/, '$1 - $2');
        } else if (number.length === 10) {
            number = number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 - $3');
        } else if (number.length > 10) {
            number = number.slice(0, 10);
            number = number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 - $3');
        }

        e.target.value = number;
    });
};

const isPhoneValid = (phoneInput) => {
    let fphone = phoneInput.value.trim();
    let errormsg = 'Sorry, that is not a valid area code.';
    const errorElement = document.querySelector('.invalid-number small');

    if (fphone !== '') {
        // Remove non-digit characters
        fphone = fphone.replace(/[^\d]/g, '');

        // Check for invalid area codes or sequences
        if (fphone.startsWith('0') || fphone.startsWith('1')) {
            errorElement.textContent = errormsg;
            return false;
        }

        // Check for common invalid area codes
        const invalidCodes = ['999', '888', '777', '666', '555', '444', '333', '222', '111'];
        if (invalidCodes.some(code => fphone.startsWith(code))) {
            errorElement.textContent = errormsg;
            return false;
        }

        // Check if the last four digits are '0000'
        if (fphone.endsWith('0000')) {
            errorElement.textContent = errormsg;
            return false;
        }

        // Check for repeated digits (e.g., 1111111111)
        if (/^(.)\1+$/.test(fphone)) {
            errorElement.textContent = errormsg;
            return false;
        }

        // Ensure phone number is exactly 10 digits
        if (fphone.length !== 10) {
            errormsg = 'Please enter a 10-Digit Phone Number';
            errorElement.textContent = errormsg;
            return false;
        } else {
            // Format the phone number
            const formattedPhone = `(${fphone.substring(0, 3)}) ${fphone.substring(3, 6)} - ${fphone.substring(6)}`;
            phoneInput.value = formattedPhone;
            errorElement.textContent = ''; // Clear any error message
            return true;
        }
    } else {
        errormsg = 'Please enter a 10-Digit Phone Number';
        errorElement.textContent = errormsg;
        return false;
    }
};


export function initializeLeadForm() {
    const form = document.getElementById('lead-form-modal');
    if (!form) {
        return;
    }
    const fullNameInput = document.getElementById('full-name');
    const phoneInput = document.getElementById('phone_number');
    const emailInput = document.getElementById('email-address');
    const certifyCheckbox = document.getElementById('certify');
    const submitButton = form.querySelector('input[type="submit"]');
    const submitError = document.querySelector('.submit-error');
    const modalFormContainer = document.querySelector('.modal-form');
    const modalThankYouContainer = document.querySelector('.modal-thank-you');
    const firstNameSpan = document.querySelector('.first-name');
    const affiliateId = document.getElementById('affiliate-id');
    const authToken = document.getElementById('auth-token');
    const campaignId = document.getElementById('campaign-id');
    const campaignKey = document.getElementById('campaign-key');
    const currentLeadId = document.getElementById('current-lead-id');
    const fakeField = document.getElementById('secondary-email');
    const interestInput = document.getElementById('interest');
    const isSingleForm = form.classList.contains('single') ? true : false;
    const singleFormContainer = document.querySelector('.form-popup');
    const singleFormThankYou = document.querySelector('.form-thank-you');

    // Helper function to show validation messages
    const showError = (element, message) => {
        console.log('error', element);
        console.log('parent', element.parentElement);
        const errorSpan = element.parentElement.querySelector('.text-danger');
        errorSpan.querySelector('small').textContent = message;
        element.classList.add('is-invalid');
    };

    // Helper function to clear validation messages
    const clearError = (element) => {
        const errorSpan = element.parentElement.querySelector('.text-danger');
        errorSpan.querySelector('small').textContent = '';
        element.classList.remove('is-invalid');
    };

    // Name validation
    const validateName = () => {
        const fullName = fullNameInput.value.trim();
        if (fullName === '' || !/^[a-zA-Z\s]+$/.test(fullName)) {
            showError(fullNameInput, 'Please enter a valid full name.');
            return false;
        }
        clearError(fullNameInput);
        return true;
    };

    // Phone number validation (basic US format)
    const validatePhoneNumber = () => {

        if (isPhoneValid(phoneInput)) {
            clearError(phoneInput);
            return true;
        } else {
            showError(phoneInput, 'Please enter a valid phone number.');
            return false;
        }
    };

    // Email validation
    const validateEmail = () => {
        const email = emailInput.value.trim();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            console.log('email error', email);
            showError(emailInput, 'Please enter a valid email address.');
            return false;
        }
        console.log('email clear', email);
        clearError(emailInput);
        return true;
    };

    // Interest validation
    const validateInterest = () => {
        if (!interestInput) {
            // If the dropdown does not exist, skip validation
            return true;
        }

        const selectedValue = interestInput.value.trim();
        if (selectedValue === '' || selectedValue === 'default') { // Assuming 'default' is the placeholder value
            showError(interestInput, 'Please select an interest.');
            return false;
        }

        clearError(interestInput);
        return true;
    };

    // Certification checkbox validation
    const validateCertify = () => {
        if (!certifyCheckbox.checked) {
            showError(certifyCheckbox, 'You must certify that your information is accurate.');
            return false;
        }
        clearError(certifyCheckbox);
        return true;
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission

        if (fakeField.value !== '') {
            return false;
        }
        let success = false;

        // Validate all fields
        const isValidName = validateName(),
            isValidPhone = validatePhoneNumber(),
            isValidEmail = validateEmail(),
            isValidCertify = validateCertify(),
            isValidInterest = validateInterest();

        if (isValidName && isValidPhone && isValidEmail && isValidCertify && isValidInterest) {
            // Disable the submit button and add the 'processing' class to the parent
            submitButton.disabled = true;
            submitButton.parentElement.classList.add('processing');

            // Prepare form data
            const formData = new FormData(),
                fullName = fullNameInput.value.trim(),
                first_name = fullName.split(' ')[0],
                last_name = fullName.split(' ')[1],
                xxTrustedFormCertUrl = form.querySelector('input[name="xxTrustedFormCertUrl"]'),
                xxTrustedFormToken = form.querySelector('input[name="xxTrustedFormToken"]'),
                xxTrustedFormPingUrl = form.querySelector('input[name="xxTrustedFormPingUrl"]');
            formData.append('phone_home', phoneInput.value);
            formData.append('email_address', emailInput.value);
            formData.append('certify', certifyCheckbox.checked);
            formData.append('subSourceId', '5586');
            formData.append('affiliateInquiryId', affiliateId.value);
            formData.append('Auth_Token', authToken.value);
            formData.append('lead_id', currentLeadId.value);
            formData.append('first_name', first_name);
            formData.append('last_name', last_name);
            formData.append('zip_code', '68746');
            formData.append('city', 'Monowi');
            formData.append('state', 'Nebraska');
            formData.append('careTypeCode', interestInput ? interestInput.value : 'A'),
                formData.append('relationToResident', 'OTHER_RELATIVE');
            formData.append('moveDateRange', '_0_DAYS');
            formData.append('financialResources', 'NONE');
            formData.append('budgetMax', '1999');
            formData.append('budgetMin', '1');
            formData.append('lp_campaign_id', campaignId.value);
            formData.append('lp_campaign_key', campaignKey.value);
            formData.append('lp_response', 'json');
            formData.append('referral_url', document.referrer || window.location.href);
            formData.append('sourcechannel', 'SL');
            formData.append('xxTrustedFormCertUrl', xxTrustedFormCertUrl.value);
            formData.append('xxTrustedFormToken', xxTrustedFormToken.value);
            formData.append('xxTrustedFormPingUrl', xxTrustedFormPingUrl.value);

            // Send XHR request
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'json';
            xhr.open('POST', 'https://love.leadspediatrack.com/post.do', true);
            let errorReason = '',
                directoryFormId = isSingleForm ? 'single-page-lead-form' : 'directory-lead-form';

            xhr.onload = function () {
                console.log('xhr.status', xhr.status);
                if (xhr.status === 200) {
                    const response = xhr.response;
                    if (response.result === 'success') {
                        if (isSingleForm) {
                            singleFormContainer.style.display = 'none';
                            singleFormThankYou.style.display = 'block';
                        } else {
                            modalFormContainer.style.display = 'none';
                            modalThankYouContainer.style.display = 'block';
                        }

                        firstNameSpan.textContent = first_name;

                        const defaultData = {
                            'event': 'lead_form_submission',
                            'leadFormId': directoryFormId,
                            'submissionStatus': 'success',
                            'errorReason': 'null',
                        };
                        window.dataLayer.push(defaultData);
                        console.log(defaultData);

                        success = true;

                        submitLytics(formData);
                    } else if (response.result === 'failed') {
                        // If result is 'failed', display error
                        errorReason = response.msg + ' - ' + response.errors[0].error;
                        submitError.textContent = 'There was an error submitting the form. Please try again.';
                        submitButton.disabled = false;
                        submitButton.parentElement.classList.remove('processing');
                    } else {
                        // Handle unexpected response structure
                        errorReason = 'Unexpected response. Please try again.';
                        submitError.textContent = errorReason;
                        submitButton.disabled = false;
                        submitButton.parentElement.classList.remove('processing');
                    }
                } else {
                    // On non-200 status, show error
                    errorReason = 'There was an error submitting the form. Please try again.';
                    submitError.textContent = errorReason;
                    submitButton.disabled = false;
                    submitButton.parentElement.classList.remove('processing');
                }

                if (!success) {
                    const defaultData = {
                        'event': 'lead_form_submission',
                        'leadFormId': directoryFormId,
                        'submissionStatus': 'failure',
                    };
                    defaultData.errorReason = errorReason;
                    window.dataLayer.push(defaultData);
                    console.log(defaultData);
                }
            };

            xhr.onerror = function () {
                // Handle network error
                errorReason = 'A network error occurred. Please try again.';
                submitError.textContent = 'A network error occurred. Please try again.';
                submitButton.disabled = false;
                submitButton.parentElement.classList.remove('processing');

                const defaultData = {
                    'event': 'lead_form_submission',
                    'leadFormId': directoryFormId,
                    'submissionStatus': 'failure',
                    'errorReason': errorReason,
                };
                window.dataLayer.push(defaultData);
                console.log(defaultData);
            };

            // Send form data
            xhr.send(formData);
        }
    };

    // Event listener for form submission
    form.addEventListener('submit', handleSubmit);

    // Real-time validation on input change
    fullNameInput.addEventListener('input', validateName);
    phoneInput.addEventListener('input', validatePhoneNumber);
    emailInput.addEventListener('input', validateEmail);
    certifyCheckbox.addEventListener('change', validateCertify);
    if (interestInput) {
        interestInput.addEventListener('change', validateInterest);
    }

    // Format phone number as the user types
    formatPhoneNumber(phoneInput);
}
