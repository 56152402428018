document.addEventListener('DOMContentLoaded', function() {
    const similar_products = document.querySelector('.similar-products'),
        review_nav = document.querySelector('.anchor-link-content'),
        page_hero = document.querySelector('.page-hero'),
        page_meta = document.querySelector('.page-meta'),
        anchor_nav = document.querySelector('.product-review-nav-container'),
        review_header = document.querySelector('.product-review-header'),
        body = document.querySelector('body');

    function insertAfter(newNode, existingNode) {
        existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
    }

    if (
        typeof similar_products !== 'undefined'
        && similar_products !== null
        && similar_products.classList.contains('is-top')
        && typeof page_hero !== 'undefined' && page_hero !== null
    ) {
        if (
            body.classList.contains('page-template-review-template-2022') ||
            body.classList.contains('post-template-review-template-2022') ||
            body.classList.contains('page-template-brand-template-2023') ||
            body.classList.contains('post-template-brand-template-2023')) {
            insertAfter(similar_products, page_meta);
        } else {
            if (typeof anchor_nav !== 'undefined' && anchor_nav !== null) {
                document.body.insertBefore(similar_products, anchor_nav);
            } else if (typeof review_header !== 'undefined' && review_header !== null) {
                insertAfter(similar_products, review_header);
            } else {
                document.body.insertBefore(similar_products, review_nav);
            }
        }
        document.body.classList.add('similar-top');
    }
});
