function collapsedContent() {
    const collapse = document.getElementsByClassName('collapsable');
    let i;

    if (collapse) {
        for (i = 0; i < collapse.length; i++) {
            collapse[i].addEventListener('click', function () {
                this.classList.toggle('active');
                var content = this.nextElementSibling;
                if (content.style.maxHeight) {
                    content.style.maxHeight = null;
                } else {
                    content.style.maxHeight = content.scrollHeight + 'px';
                }

                let defaultData = {
                    'event': 'on_page_link_click',
                    'elementPlacement': 'content-accordion',
                    'elementClicked': this.classList.contains('active') ? 'open' : 'close',
                    'elementName': this.firstElementChild.textContent,
                    'pageType': window.sl_pagetype,
                    'pageUrl': window.location.href,
                }
                
                window.dataLayer.push(defaultData);

                console.log(defaultData);
            })
        }
    }
}

export default function init() {
    collapsedContent();
}
