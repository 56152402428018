
const redirect = (e) => {
    const url = e.currentTarget.querySelector('a').getAttribute('href');
    window.location.href = url;
}

const turnCardToLink = (cards) => {

    cards.forEach(card => {
        const elems = document.querySelectorAll(card);

        if (typeof elems !== 'undefined' && elems !== null) {
            elems.forEach(elem => {
                elem.addEventListener('click', (e) => redirect(e));
            });
        }
    });
}
// TODO: temporary fix for issue that needs to be addresses with the #guides-for-seniors cards implementation
setTimeout(function() {
    turnCardToLink([
        '.card',
    ]);
}, 500);
